(function ($) {

    var o = $({});

    $.suscribe = function () {

        o.on.apply(o, arguments);
    };
    $.unsuscribe = function () {

        o.off.apply(o, arguments);
    };
    $.publish = function () {

        o.trigger.apply(o, arguments);
    };

}(jQuery));