(function ($) {
    $.loadAjaxRequest = function (ev) {
        // Stop regular handling of "click" in most non-IE browsers
        ev.preventDefault();
        ev.stopPropagation();

        // Load the new content into the div (same code you had)
        $('#form-area').load($(this).attr('href'),function(){
            $('#form-area').slideDown();
            //loadIds();
        });

        // Stop regular handling of "click" in IE (and some others)
        return false;
    };
    $.submitAjaxRequest = function (ev) {

        ev.preventDefault();
        ev.stopPropagation();
        var form = $(this);
        var method = form.find('input[name="_method"]').val() || 'POST';
        $.ajax({
            type: method,
            url: form.prop('action'),
            data: form.serialize(),
            success: function () {
                var message = form.data('remote-success-message');

                if (message) {

                    swal(message, '', "success");
                }
                if (typeof laDataTable === 'undefined') {

                } else {

                        laDataTable.ajax.reload();

                }
                if (typeof laTrashTable === 'undefined') {

                } else {
                    setTimeout(function(){
                        laTrashTable.ajax.reload();
                    }, 500);

                }
                $('#form-area').html('').slideUp();

            },
            error: function (jqXhr, json, errorThrown) {
                var errors = jqXhr.responseJSON;
                var errorsHtml = "<ul>";
                $.each(errors, function (key, value) {
                    errorsHtml += '<li>' + value[0] + '</li>';
                });
                errorsHtml += '</ul>';

                swal({
                    title: 'Error!',
                    text: errorsHtml,
                    type: "error",
                    html: true});


            }
        });

    };

    $.confirmAjaxRequest = function (ev) {

        ev.preventDefault();
        ev.stopPropagation();

        var form = $(this);
        var prompt = form.data('table-confirm');
        var promptmessage = form.data('table-message');
        var method = form.find('input[name="_method"]').val() || 'POST';

        swal({
            title: prompt,
            text: promptmessage,
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "#DD6B55",
            confirmButtonText: "Ok!"
        }, function(isConfirm) {
            if (isConfirm) {


                $.ajax({
                    type: method,
                    url: form.prop('action'),
                    data: form.serialize(),
                    success: function () {
                        var message = form.data('remote-success-message');

                        if (message) {
                            swal(message, '', "success");
                        }
                        if (typeof laDataTable === 'undefined') {

                        } else {
                            laDataTable.ajax.reload();
                        }
                        if (typeof laTrashTable === 'undefined') {

                        } else {
                            setTimeout(function(){
                                laTrashTable.ajax.reload();
                            }, 500);
                        }
                        $('#form-area').html('').slideUp();
                    },
                    error: function (jqXhr, json, errorThrown) {
                        var errors = jqXhr.responseJSON;
                        var errorsHtml = "<ul>";
                        $.each(errors, function (key, value) {
                            errorsHtml += '<li>' + value[0] + '</li>';
                        });
                        errorsHtml += '</ul>';
                        swal({
                            title: 'Error!',
                            text: errorsHtml,
                            type: "error",
                            html: true});

                    }
                });

            }
        });
    };

    $.bindjaxRequest = function (e) {

        // Forms maked with "data-remote" attribute will submit via AJAX
        $('form[data-remote]').off('submit').on('submit', $.submitAjaxRequest);

        // Forms maked with "data-confirm" attribute will ask for confirmation
        $('input[data-confirm], button[data-confirm], a[data-confirm]').off('click').on('click', $.confirmAjaxRequest);

        // Forms maked with "data-remote" attribute will submit via AJAX
        $('button[data-flash-dismiss]').off('click').on('click', function () {
            $.publish('flash.submitted');
        });

        // Forms maked with "data-remote" attribute will submit via AJAX
        $('#content-table tbody').off('submit', 'form[data-table-confirm]').on('submit', 'form[data-table-confirm]', $.confirmAjaxRequest);
        // Edit buttons will load edit form
        $('#content-table tbody').off('click', 'a').on('click', 'a', $.loadAjaxRequest);

        // Forms maked with "data-remote" attribute will submit via AJAX
        $('#trashed-table tbody').off('submit', 'form[data-table-confirm]').on('submit', 'form[data-table-confirm]', $.confirmAjaxRequest);
        // Edit buttons will load edit form
        $('#trashed-table tbody').off('click', 'a').on('click', 'a', $.loadAjaxRequest);
        // Links for creating new forms attached to ajax
        $('#create-new-link').off('click').on('click', $.loadAjaxRequest);
    };
    $.bindjaxRequest();


    $('#default-view-btn').on('click', function (e) {
        $('#trash-table-area').slideUp();
        $('#content-table-area').slideDown();
    });

    $('#trash-view-btn').on('click', function (e) {
        $('#trash-table-area').slideToggle();
        $('#content-table-area').slideToggle();
        $.viewTrashed(trashroute,columnas,language);
    });
    $.viewContent = function (route, columnas,language) {
        laDataTable = $('#content-table').DataTable({
            processing: true,
            serverSide: true,
            ajax: route,
            columns: columnas,
            language: {
                url: language
            }
        });
        $.bindjaxRequest();

    }
    $.viewTrashed = function (route, columnas,language) {

        if (!$.fn.DataTable.isDataTable('#trashed-table')) {

            laTrashTable = $('#trashed-table').DataTable({
                processing: true,
                serverSide: true,
                ajax: route,
                columns: columnas,
                language: {
                    url: language
                }
            });
            $.bindjaxRequest();

        }
    }
    $('body').ajaxStart(function() {
        $(this).css({'cursor' : 'wait'});
    }).ajaxStop(function() {
        $(this).css({'cursor' : 'default'});
    });
}(jQuery));




